
import { Options, Vue } from 'vue-class-component';
import { CustomerClient, ExhibitionClient, PatronageClient } from '@/services/services'
import { StorageServices } from '@/services/StorageServices';
import { LocalizationServices } from '@/services/LocalizationServices';
import InteractionModal from '@/components/modals/interactionModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import PatronageProgramModal from '@/components/modals/patronageProgramModal.vue';
import PatronageBackersModal from '@/components/modals/patronageBackersModal.vue';
import PatronageDonationModal from '@/components/modals/patronageDonationModal.vue';
import RegisterLoginModal from '@/components/modals/registerLoginModal.vue';
import SwiperGallery from '@/components/modals/swiperGalleryModal.vue';
import GiveFeedback from '@/components/giveFeedback.vue';
import ProgressBar from '@/components/progressBar.vue';
import store from '@/store';
import * as OM from '@/model';

@Options({
    components: {
        GiveFeedback,
        ProgressBar
    }
})
export default class ExhibitionItem extends Vue {

    operaPin: string = "";
    opera: OM.ExhibitionItemVm = new OM.ExhibitionItemVm();

    loggedRole: string = "";
    patronage: OM.PatronageVM = new OM.PatronageVM();

    showMore: boolean = false;
    needLessButton: boolean = false;

    mostra: OM.GetExhibitionVm = StorageServices.getExhibition();
    loaded: boolean = false;
   
    created() {
        this.operaPin = this.$route.params.pin.toString();
        this.loggedRole = StorageServices.getLoggedUser().role;    
        this.init();
    }

    init() {
        let votate = StorageServices.getOpereVotate(store.state.exhibition.identifier);
        let showModal = false;
        if(votate.indexOf(this.operaPin) == -1)
            showModal = true;

        var exhibitionId = store.state.exhibition.identifier;
        ExhibitionClient.getExhibitionItem(this.operaPin, exhibitionId)
        .then(x => {
            this.opera = x;

            PatronageClient.getPatronage(this.opera.identifier)
            .then(x => {
                this.patronage = x;

                if(showModal && store.state.exhibition.emotions)
                    this.showInteractionModal();
                            
                this.loaded = true;

                this.$nextTick(() => {
                    let operaDescriptionEl = <HTMLElement>this.$refs.operaDescription;
                    let windowTreshold = window.innerHeight * 19 / 100;
                    if(parseFloat(getComputedStyle(operaDescriptionEl).height) > windowTreshold){
                        this.showMore = true;
                        this.needLessButton = true;
                    }
                })
            })

            // gtag('event', "opera", {
            //     'event_category': 'momiapp',
            //     'event_label': vc.opera.title.values[LocalizationServices.GetLanguage().key],
            //     'value': ''
            // });
        },
        error => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_DoesntExistAnItemWithThisPin?", "Doesn't exist an item with this pin"),
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$router.back();
                    this.$opModal.closeLast();
                }
            })
        })
    }

    showInteractionModal(){
        this.$opModal.show(InteractionModal, {
            exhibitionItemId: this.opera.identifier,
            exhibitionItemPin: this.operaPin,
            imageUrl: this.opera.imageUrl
        });
    }

    showSwiperGalleryModal(gallery: OM.LocalizedThumbnailItem[], index: number, isVideo: boolean) {
        var urls = [];
        gallery.forEach(element => {
            urls.push(element.url);
        });

        this.$opModal.show(SwiperGallery, {
            isVideo: isVideo,
            gallery: urls,
            index: index
        });
    }

    toggleFavorite() {
        if(!this.opera.inFavorite) {
            CustomerClient.addFavorite(this.opera.identifier, false)
            .then(x => {})
        }
        else {
            CustomerClient.removeFavorite(this.opera.identifier, false)
            .then(x => {})
        }

        this.opera.inFavorite = !this.opera.inFavorite;
    }

    openPatronageInfo() {
        this.$opModal.show(PatronageProgramModal, {
            description: this.patronage.description
        })
    }

    openPatronageBackers() {
        this.$opModal.show(PatronageBackersModal, {
            patronageIdentifier: this.patronage.identifier
        })
    }

    backItsRestoration() {
        if(this.loggedRole == "anonymous") {
            var text = this.$localizationService.getLocalizedValue("app_LoginRequiredForDonation", "Login is required to make a donation on our patronage program.")
            this.$opModal.show(RegisterLoginModal, {
                text: text
            })
        }
        else {
            this.$opModal.show(PatronageDonationModal, {
                patronage: this.patronage,
                exhibitionItemTitle: this.opera.titleString,
                exhibitionItemImageUrl: this.opera.imageUrl,
                callback: () => {
                    this.init();
                    this.$opModal.closeLast();
                }
            })
        }
    }
}
